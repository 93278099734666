
import { StorageServices } from '@/services/StorageServices';
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import * as OM from '@/Model';

@Options({
    components: {
    }
})
export default class ProfileQrCodeModal extends Vue {

    @Prop() callback: any;

    qrCodeWith: number = 0;
    loggedUser: OM.LoggedUserVM = new OM.LoggedUserVM();
    loaded: boolean = false;

    mounted() {
        this.loggedUser = StorageServices.getLoggedUser();
        this.qrCodeWith = (<HTMLElement>this.$refs.modalBody).clientWidth - 40;

        setTimeout(() => {
            this.loaded = true;
        }, 100);
    }

}
