
import { Options, Vue } from 'vue-class-component';
import { ChallengeClient, GameClient } from '@/services/Services';
import InfoButton from '../components/infoButton.vue';
import ProfileQrCodeModal from '../modals/profileQrCodeModal.vue';
import * as OM from '@/Model';

@Options({
    components: {
        InfoButton
    }
})
export default class ChallengeDetail extends Vue {

    stageIdentifier: string = "";

    stage: OM.StageVM = new OM.StageVM();
    stageResume: OM.UserGameResume = new OM.UserGameResume();
    
    created() {
        this.stageIdentifier = this.$route.params.challengeIdentifier.toString();
        this.init();
    }

    init() {
        Promise.all([
            ChallengeClient.getById(this.stageIdentifier),
            GameClient.getUserGameResume()
        ])
        .then(xs => {
            this.stage = xs[0];
            this.stageResume = xs[1];
        })
    }

    getChallengeStatus() {
        if(!this.stageResume)
            return "discovery";

        var doneExist = this.stageResume.stagesDone.find(x => x.stageIdentifier == this.stage.identifier);
        return doneExist ? 'completed' : 'discovery';
    }

    openQrCodeModal() {
        this.$opModal.show(ProfileQrCodeModal, {
            callback: () => {
                this.$opModal.closeLast();
                this.init();
            }
        })
    }

}
